import { useLocation, useNavigate } from '@remix-run/react';
import { NavigateOptions } from 'react-router';
import { InferType, Schema } from 'yup';

/**
 * @name useHash
 * @description This hook makes it a bit easier to work with query params
 * via React Router + Remix. Each of these functions will automatically
 * call the `navigate` function from Remix to update the URL.
 */
export const useHash = <HashSchema extends Schema>(schema: HashSchema) => {
  type Hash = InferType<typeof schema>;

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Setup
  const rawHash = location.hash;
  const trimmedHash = rawHash.replace(/^#/, '');
  const decodedHash =
    decodeURIComponent(trimmedHash) || JSON.stringify(schema.getDefault());
  const parsedHash = JSON.parse(decodedHash);
  const hash: Hash = schema.cast(parsedHash, {
    assert: false,
    stripUnknown: true,
  });

  // Handlers
  const setHash = (newHash: Hash, options?: NavigateOptions | undefined) => {
    const updatedHash = { ...hash, ...newHash };
    const stringifiedHash = JSON.stringify(updatedHash);
    const encodedHash = encodeURIComponent(stringifiedHash);
    const prefixedHash = `#${encodedHash}`;
    navigate(
      { ...location, hash: prefixedHash },
      { preventScrollReset: true, replace: true, ...options },
    );
  };

  // Life Cycle

  // 🔌 Short Circuits

  return { hash, setHash };
};
