type EnvironmentValues = Record<string, string>;

declare global {
  interface Window {
    env: EnvironmentValues;
  }
}

// We duplicate these here to prevent circular dependencies with `config/environment.ts`
const NODE_ENV = process.env.NODE_ENV || 'development';
const IS_TEST = NODE_ENV === 'test';
const IS_BROWSER = typeof document !== 'undefined';

/**
 * @name getEnv
 * @description Our Remix applications are both Server and Client side rendered.
 * This function will grab the environment variables from the "process" or
 * "window" object depending on the context.
 */
function getEnv(): EnvironmentValues {
  const isClient = IS_BROWSER && !IS_TEST;
  return (isClient ? window.env : process.env) as EnvironmentValues;
}

/**
 * @name parseEnvValue
 * @description Parses string, boolean, and falsy values from the passed in
 * EnvironmentValues map.
 */
export function parseEnvValue<T = string>(
  key: string,
  env: EnvironmentValues,
): T {
  const value = env[key];
  let strValue: any = value ?? `__${key}__`;

  if (!value || value === 'undefined') strValue = undefined;
  if (strValue === 'false') strValue = false;
  if (strValue === 'null') strValue = null;
  if (strValue === 'true') strValue = true;

  return strValue as T;
}

/**
 * @name parseEnvNumber
 * @description Parses number values from the passed in EnvironmentValues map.
 */
export function parseEnvNumber(
  key: string,
  env: EnvironmentValues,
  fallback: number,
): number {
  const strValue = parseEnvValue(key, env);
  return isNaN(+strValue) ? fallback : parseFloat(strValue);
}

/**
 * @name getFromEnv
 * @description A type-safe helper that grabs EnvironmentValues variables that
 * we've exposed to both the "client" and "server" side. This approach should
 * not be taken for any variables that are "sensitive" in nature.
 *
 * This will do auto-conversions for falsy value and booleans, but numbers will
 * not auto convert by default (see getNumberFromEnv).
 */
export const getFromEnv = <T = string>(key: string) =>
  parseEnvValue<T>(key, getEnv() as EnvironmentValues);

/**
 * @name getNumberFromEnv
 * @description A type-safe helper that grabs EnvironmentValues variables that
 * should be treated as a number. If the env value is not found, it will use
 * the provided fallback.
 */
export const getNumberFromEnv = (key: string, fallback: number): number =>
  parseEnvNumber(key, getEnv(), fallback);
