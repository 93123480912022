import {
  SortingState,
  RowPinningState,
  RowSelectionState,
} from '@tanstack/react-table';
import {
  queryParamsRowPinning,
  hashParamsRowSelection,
  queryParamsSorting,
} from '../config';

export const sortsQueryParamToSortingState = (sorts?: string[]) => {
  if (!queryParamsSorting.isValidSync({ sorts }, { strict: true })) {
    console.warn('Invalid sorts query param', sorts);
  }
  if (!sorts?.length) return [] as SortingState;

  const sorting: SortingState = sorts
    .map((sort) => {
      const [direction, id] = sort.split('-') as ['asc' | 'desc', string];
      if (!['asc', 'desc'].includes(direction)) return null;
      return { desc: direction === 'desc', id };
    })
    .filter(Boolean) as SortingState;
  return sorting;
};

export const sortingStateToSortsQueryParam = (sorting: SortingState) => {
  const sorts = sorting.map(({ desc, id }) => `${desc ? 'desc' : 'asc'}-${id}`);
  return sorts.length ? sorts : undefined;
};

export const pinnedRowsQueryParamToRowPinningState = (
  pinnedRowsProp?: string[],
) => {
  const pinnedRows = pinnedRowsProp ? Array.from(pinnedRowsProp) : undefined;
  if (!queryParamsRowPinning.isValidSync({ pinnedRows }, { strict: true })) {
    console.warn('Invalid pinnedRows query param', pinnedRows);
  }
  if (!pinnedRows?.length) return { bottom: [], top: [] } as RowPinningState;

  const rowPinning: RowPinningState = pinnedRows.reduce(
    (rowPinning, pinnedRow) => {
      const { top = [], bottom = [] } = rowPinning;
      const [position, ...rest] = pinnedRow.split('-') as [
        'top' | 'bottom',
        ...string[],
      ];
      if (!['top', 'bottom'].includes(position)) return rowPinning;
      const id = rest.join('-');
      if (position === 'top') return { bottom, top: top.concat(id) };
      else return { bottom: bottom.concat(id), top };
    },
    { bottom: [], top: [] } as RowPinningState,
  );
  return rowPinning;
};

export const rowPinningStateToPinnedRowsQueryParam = (
  rowPinning: RowPinningState,
) => {
  const { top = [], bottom = [] } = rowPinning;
  const pinnedRows = [
    ...top.map((id) => `top-${id}`),
    ...bottom.map((id) => `bottom-${id}`),
  ];
  return pinnedRows;
};

export const selectedRowsHashParamToRowSelectionState = (
  selectedRowsProp?: string[],
) => {
  const selectedRows = selectedRowsProp
    ? Array.from(selectedRowsProp)
    : undefined;
  if (!hashParamsRowSelection.isValidSync({ selectedRows }, { strict: true })) {
    console.warn('Invalid selectedRows query param', selectedRows);
  }
  if (!selectedRows?.length) return {};

  const rowSelection = selectedRows.reduce((rowSelection, uuid) => {
    return { ...rowSelection, [uuid]: true };
  }, {} as RowSelectionState);
  return rowSelection;
};

export const rowSelectionStateToSelectedRowsHashParam = (
  rowSelection: RowSelectionState,
) => {
  const selectedRows = Object.keys(rowSelection).filter(
    (key) => rowSelection[key],
  );
  return selectedRows.length > 0 ? selectedRows : undefined;
};
