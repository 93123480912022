import { Input, InputProps } from '@shiftsmartinc/shiftsmart-ui';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { InferType, Schema } from 'yup';
import { debounce } from 'lodash';
import { useHash } from '../hooks/useHash';

export interface HashTextProps<S extends Schema>
  extends Omit<InputProps, 'id'> {
  id: keyof InferType<S>;
  schema: S;
}

/**
 * @name HashText
 * @description An Input component that controls a hash param
 */
export const HashText = <S extends Schema>(props: HashTextProps<S>) => {
  const { id, schema, ...inputProps } = props;

  // Hooks
  const { hash, setHash } = useHash(schema);

  const { [id]: textHash = '', ...otherHashes } = hash;
  const [text, setText] = useState(textHash);

  // Setup

  // Handlers
  const setTextHash = useCallback(
    debounce(
      (text?: string) => {
        setHash({ [id]: text, page: undefined, ...otherHashes });
      },
      200,
      { leading: true, trailing: true },
    ),
    [id, otherHashes],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    setTextHash(event.target.value || undefined);
  };

  // Markup
  const defaultLeftIcon = (
    <MagnifyingGlass className="text-gray-6 absolute top-1/2 mx-2 -translate-y-1/2 transform" />
  );

  // Life Cycle
  useEffect(() => {
    setText(textHash);
  }, [textHash]);

  // 🔌 Short Circuits

  return (
    <Input
      id={String(id)}
      leftIcon={defaultLeftIcon}
      onChange={onChange}
      type="search"
      value={text}
      {...inputProps}
    />
  );
};
