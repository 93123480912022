import * as React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

export interface MockRouteWithOutletContextProps<T = any> {
  children: React.ReactNode;
  context: T;
  path?: string;
}

/**
 * @name MockRouteWithOutletContext
 * @external https://stackoverflow.com/questions/70507413/react-router-useoutletcontext-testing
 * @description This mock is used when we need to tests components that use
 * the `useOutletContext` hook. It provides a proper context to the component
 * using React Routers `Outlet` component.
 */
export const MockRouteWithOutletContext = <T,>({
  context,
  children,
  path = '/',
}: MockRouteWithOutletContextProps<T>) => {
  return (
    <Routes>
      <Route element={<Outlet context={context as T} />} path={path}>
        <Route element={children} index={true} />
      </Route>
    </Routes>
  );
};
