import { IParseOptions, IStringifyOptions } from 'qs';

export const parseOptions: IParseOptions = {
  arrayLimit: 1000,
  ignoreQueryPrefix: true,
  parseArrays: true,
  strictNullHandling: true,
};

export const stringifyOptions: IStringifyOptions = {
  arrayFormat: 'brackets',
  strictNullHandling: true,
};
