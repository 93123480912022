import { set } from 'lodash';
import { InferType, Schema, SchemaFieldDescription, reach } from 'yup';
import { stringToSchemaValue, stringToValue } from './valueString';

/**
 * @name formDataToJson
 * @description Converts FormData to JSON, following a yup schema where possible
 */
export const formDataToJson = <S extends Schema>(
  formData: FormData,
  schema: S,
  hasLazyLoadedSchema?: boolean,
) => {
  const json: InferType<typeof schema> = {};

  formData.forEach((valueString, key) => {
    if (typeof valueString !== 'string') return;
    const value = formDataEntryToJson(
      [key, valueString],
      schema,
      hasLazyLoadedSchema,
    );
    set(json, key, value);
  });

  try {
    schema.validateSync(json);
  } catch (error: unknown) {
    console.error('Error validating formData:', {
      error,
      formData,
      json,
      schema,
    });

    throw error;
  }

  return json;
};

/**
 * @name formDataEntryToJson
 * @description Converts a single FormDataEntry to JSON, following a yup schema where possible
 */
const formDataEntryToJson = <S extends Schema>(
  [key, valueString]: [string, string],
  schema: S,
  hasLazyLoadedSchema?: boolean,
) => {
  let schemaField: SchemaFieldDescription | undefined;

  try {
    schemaField = reach(schema, key).describe();
  } catch (error) {
    schemaField = undefined;
  }

  if (schemaField && schemaField?.type !== 'mixed') {
    try {
      return stringToSchemaValue(valueString, key, schema);
    } catch {
      console.error('Error converting formDataEntry to JSON:', {
        key,
        schema,
        schemaField,
        valueString,
      });
    }
  } else if (!schemaField && hasLazyLoadedSchema) {
    if (valueString === '') return undefined;
  }

  return stringToValue(valueString);
};
