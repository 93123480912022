import { useMemo, useRef } from 'react';
import { IS_BROWSER } from '../config';

/**
 * @name useIsInBottomHalfOfScreen
 * @description A hook to determine if an element is in the bottom half of the screen
 */
export const useIsInBottomHalfOfScreen = <E extends HTMLElement>() => {
  // Hooks
  const ref = useRef<E>(null);

  // Setup
  const elementTop = ref.current?.getBoundingClientRect().top ?? 0;

  const isInBottomHalfOfScreen = useMemo(() => {
    if (!IS_BROWSER || !ref?.current) return false;
    const elementOffset = window.scrollY + elementTop;
    return elementOffset > window.innerHeight / 2;
  }, [elementTop]);

  // Handlers

  // Life Cycle

  // 🔌 Short Circuits

  return { isInBottomHalfOfScreen, ref };
};
