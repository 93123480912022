import { parse } from 'qs';
import { InferType, Schema } from 'yup';
import { parseOptions, queryParamsSorting } from '../config';

export function validateQueryParams<S extends Schema>(url: string, schema: S) {
  /**
   * The below operation converts null values to empty strings '', so it should not be used
   * const queryString = new URL(url).searchParams.toString()
   */
  const queryString = url.split('?')?.[1] ?? '';

  type QueryParamsRaw = Record<string, string>;
  const queryParamsRaw = parse(queryString, parseOptions) as QueryParamsRaw;

  type QueryParams = InferType<typeof schema>;
  const queryParams: QueryParams = schema.cast(queryParamsRaw, {
    stripUnknown: true,
  });

  return queryParams;
}

export function sortsQueryParamToMongo$sort<T extends Record<string, unknown>>(
  sorts?: string[],
) {
  if (!queryParamsSorting.isValidSync({ sorts }, { strict: true })) {
    console.warn('Invalid sorts query param', sorts);
  }
  type Sorting = Record<keyof T, 1 | -1>;

  if (!sorts?.length) return {} as Sorting;

  return sorts?.reduce(($sort, sort) => {
    const [direction, id] = sort.split('-') as ['asc' | 'desc', keyof T];
    return { ...$sort, [id]: direction === 'desc' ? -1 : 1 };
  }, {} as Record<keyof T, 1 | -1>);
}
