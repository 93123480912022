import { object, array, string, number } from 'yup';

export const queryParamsPagination = object({ page: number().min(0) });

export const sortsRegex = /^(asc|desc)-[\w-]+$/;
export const queryParamsSorting = object({
  sorts: array(string().defined().required().matches(sortsRegex)).min(1),
});

export const pinnedRowsRegex = /^(top|bottom)-[\w-]+$/;
export const queryParamsRowPinning = object({
  pinnedRows: array(string().defined().required().matches(pinnedRowsRegex)).min(
    1,
  ),
});

export const queryParamsColumnVisibility = object({
  hiddenColumns: array(string().defined().required().min(1)).min(1),
});

export const hashParamsRowSelection = object({
  selectedRows: array(string().defined().required().min(1)).min(1),
});
