import { useSearchParams } from '@remix-run/react';
import { parse, stringify } from 'qs';
import { InferType, Schema } from 'yup';
import { parseOptions, stringifyOptions } from '../config';

/**
 * @name useQueryParams
 * @description This hook makes it a bit easier to work with query params
 * via React Router + Remix. Each of these functions will automatically
 * call the `navigate` function from Remix to update the URL.
 */
export const useQueryParams = <QueryParamsSchema extends Schema>(
  schema: QueryParamsSchema,
) => {
  type QueryParams = InferType<typeof schema>;

  // Hooks
  /**
   * The below operation may be converting null values to empty strings '',
   * so it may need to be replaced with: const { search } = useLocation();
   */
  const [searchParams, setSearchParams] = useSearchParams();

  // Setup
  const params: QueryParams = parse(searchParams.toString(), parseOptions);

  try {
    schema.validateSync(params);
  } catch (error) {
    console.error('Invalid query params:', { error, params, schema });
  }

  // Handlers
  const setParams = (newParams: QueryParams) => {
    const updatedParams = { ...params, ...newParams };
    const newQueryString = stringify(updatedParams, stringifyOptions);
    const newSearchParams = new URLSearchParams(newQueryString);
    setSearchParams(newSearchParams);
  };

  // Life Cycle

  // 🔌 Short Circuits

  return { params, setParams };
};
