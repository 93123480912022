import { InferType, Schema } from 'yup';
import {
  SelectMultiple,
  SelectMultipleOption,
  SelectMultipleProps,
} from '@shiftsmartinc/shiftsmart-ui';
import { useQueryParams } from '../../global/hooks/useQueryParams';

export interface QueryParamSelectMultipleProps<S extends Schema>
  extends Omit<SelectMultipleProps, 'id'> {
  id: keyof InferType<S>;
  schema: S;
}

/**
 * @name QueryParamSelectMultiple
 * @description A SelectMultiple component that controls a QueryParam
 */
export const QueryParamSelectMultiple = <S extends Schema>(
  props: QueryParamSelectMultipleProps<S>,
) => {
  const { className, id, options, schema, ...selectMultipleProps } = props;

  // Hooks
  const { params, setParams } = useQueryParams(schema);

  // Setup
  const values = params[id];

  const selectedOptions = options.filter(({ value }) =>
    values?.includes(value),
  );

  // Handlers
  const onChange = (selectedOptions?: SelectMultipleOption[]) => {
    setParams({
      [id]: selectedOptions?.length
        ? selectedOptions.map(({ value }) => value)
        : undefined,
      page: undefined,
    });
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <SelectMultiple
      className={className}
      defaultValue={selectedOptions}
      id={String(id)}
      onChange={onChange}
      options={options}
      {...selectMultipleProps}
    />
  );
};
