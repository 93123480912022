import * as React from 'react';
import { NavLink, NavLinkProps, useLocation } from '@remix-run/react';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useKeyPress } from '@shiftsmartinc/react-hooks';
import { ArrowLineLeft, ArrowLineRight } from '@phosphor-icons/react';

export interface SidebarLink extends Omit<NavLinkProps, 'children'> {
  children?: NavLinkProps['children'] | SidebarLink[];
  icon?: JSX.Element;
  title: string;
}

export interface SidebarProps {
  className?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  isCollapsed?: boolean;
  links: SidebarLink[];
  onToggleCollapse?: (collapsed: boolean) => void;
}

/**
 * @name Sidebar
 * @description A sidebar navigation component that displays links composed
 * by an icon and a title. Supports navigation by wrapping NavLink from Remix.
 * Accepts a header and a footer.
 */
export const Sidebar = (props: SidebarProps) => {
  const {
    className,
    isCollapsed = false,
    footer,
    header,
    links,
    onToggleCollapse,
  } = props;

  // Hooks
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const location = useLocation();

  // Setup

  // Handlers
  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
    onToggleCollapse?.(!collapsed);
  };

  // Markup
  const ToggleCollapseArrow = collapsed ? ArrowLineRight : ArrowLineLeft;

  const renderLink = (link: SidebarLink, isNested = false) => {
    const { className: classNameLink, icon, title, to, ...rest } = link;

    const children = link.children;
    const classes = typeof classNameLink === 'string' ? classNameLink : '';
    const pathname = typeof to === 'string' ? to : to.pathname;

    // No link to navigate to, don't render the markup
    if (!pathname) return null;

    const isArray = Array.isArray(children);
    const isActiveParent = isArray && location.pathname.includes(pathname);
    const isExactMatch = location.pathname === pathname;
    const showChildren = isArray && isActiveParent;

    return (
      <React.Fragment key={`${pathname}-${isActiveParent.toString()}`}>
        <NavLink
          className={({ isActive }) => {
            return classnames(
              'font-500 flex flex-row gap-3 whitespace-nowrap text-sm',
              isNested ? 'py-1' : 'py-2',
              {
                'pl-1': isNested && isCollapsed,
                'pl-5': isNested && !isCollapsed,
                'text-brand':
                  (isActive && !isActiveParent) ||
                  (isExactMatch && isActiveParent),
                'text-gray-2': !isActive,
                'text-xs font-semibold': isNested,
              },
              classes,
            );
          }}
          key={pathname}
          prefetch="intent"
          to={pathname}
          {...rest}
        >
          <div className={classnames({ '!text-gray-2': false })}>{icon}</div>
          <span
            className={classnames('transition-opacity', {
              'opacity-0': collapsed,
              'opacity-100': !collapsed,
            })}
          >
            {title}
          </span>
        </NavLink>
        {showChildren && children.map((child) => renderLink(child, true))}
      </React.Fragment>
    );
  };

  // Life Cycle
  useKeyPress(handleToggleCollapse, '/', true);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'border-gray-8 flex flex-col justify-between overflow-hidden border-r transition-all duration-300',
        {
          'w-[240px]': !collapsed,
          'w-[72px]': collapsed,
        },
        className,
      )}
    >
      <div
        className={classnames('flex flex-1 flex-col p-6', {
          'w-[240px]': !collapsed,
          'w-[72px]': collapsed,
        })}
      >
        {header}
        <div className="flex flex-1 flex-col justify-between">
          <div className="flex flex-col gap-2">
            {links.map((link) => renderLink(link))}
          </div>
          {footer}
        </div>
      </div>
      <div
        className="background-main border-gray-8 border-box sticky bottom-0 flex h-4 flex-row items-center justify-center border-t py-8 pt-[31px] hover:cursor-pointer"
        data-testid="toggle-collapse"
        onClick={handleToggleCollapse}
      >
        <ToggleCollapseArrow size={24} />
      </div>
    </div>
  );
};
