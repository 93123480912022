import { isArray, isObject, isDate } from 'lodash';
import { ReactNode } from 'react';

/**
 * @name formValuesToHiddenInputs
 * @description This is useful for nested objects in form data that you just want to pass through and not alter
 * It essentially takes any object and coverts it to individual hidden inputs in the DOM with the correct key-value pairs
 */
export const formValuesToHiddenInputs = (formValues: any, prefix?: string) => {
  const inputs: Array<ReactNode> = [];
  if (!formValues) return [];
  const convertValuesToInputs = (object: any, prefix = '') => {
    Object.entries(object).forEach(([key, value]) => {
      // Handle dates first since dates will also trigger the isObject conditional
      if (isDate(value)) {
        const newNode = (
          <input
            key={`${prefix}${key}`}
            name={`${prefix}${key}`}
            readOnly={true}
            type="hidden"
            value={value.toISOString()}
          />
        );
        inputs.push(newNode);
      } else if (isObject(value)) {
        convertValuesToInputs(value, `${prefix}${key}.`);
      } else if (isArray(value)) {
        value.forEach((listItem, index) =>
          convertValuesToInputs(listItem, `${prefix}${index}.`),
        );
      } else {
        const newNode = (
          <input
            key={`${prefix}${key}`}
            name={`${prefix}${key}`}
            readOnly={true}
            type="hidden"
            value={String(value)}
          />
        );
        inputs.push(newNode);
      }
    });
  };
  convertValuesToInputs(formValues, prefix);
  return inputs;
};
