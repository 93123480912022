import {
  Links,
  LiveReload,
  Meta,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';
import { ReactElement } from 'react';

export interface ApplicationHTMLProps<EnvType> {
  children: ReactElement | ReactElement[];
  env?: EnvType;
}

/**
 * @name ApplicationHTML
 * @description The HTML document that is rendered on the server and
 * client side.
 */
export const ApplicationHTML = <T,>(props: ApplicationHTMLProps<T>) => {
  const { children, env = {} } = props;

  // Hooks

  // Setup
  const environment = `window.env = ${JSON.stringify(env)}`;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          content="width=device-width,initial-scale=1,maximum-scale=1"
          name="viewport"
        />

        {/* Allow anything dynamic to override our defaults */}
        <Meta />
        <Links />
      </head>
      <body className="background-main content-default flex min-h-screen flex-col">
        {children}

        {/* We make a very specific subset of our end available on the client side */}
        <script dangerouslySetInnerHTML={{ __html: environment }} />

        {/* Remix related, leave these in this order, at the bottom */}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
};
