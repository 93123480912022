import { getFromEnv, getNumberFromEnv } from '../utils';

/**
 * @description Commonly used environment checks.
 */
export const NODE_ENV = getFromEnv('NODE_ENV') || 'development';
export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const IS_PRODUCTION = NODE_ENV === 'production';
export const IS_TEST = NODE_ENV === 'test';

/**
 * @see https://remix.run/docs/en/main/pages/gotchas#typeof-window-checks
 */
export const IS_BROWSER = typeof document !== 'undefined';
export const IS_SERVER = typeof document === 'undefined';

/**
 * @description Telemetry settings are managed via Helm charts.
 * @see https://github.com/shiftsmartinc/shiftsmart-helm-charts/blob/main/config/shiftsmart-telemetry-settings/values.yaml
 */
export const SHIFTSMART_RUM_ENABLED = getFromEnv('SHIFTSMART_RUM_ENABLED');
export const SHIFTSMART_SAMPLE_RATE = getNumberFromEnv('SHIFTSMART_SAMPLE_RATE', 0); // prettier-ignore
