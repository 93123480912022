import { Camera, CameraSlash, Image, XCircle } from '@phosphor-icons/react';
import { colors } from '@shiftsmartinc/style-guide';

export interface InShiftTaskImagePlaceholderProps {
  icon?: 'image' | 'camera' | 'camera-slash' | 'circle-x';
  text?: string;
}

/**
 * @name InShiftTaskImagePlaceholder
 * @description A placeholder to be shown for in shift task images unhappy scenarios
 */
export const InShiftTaskImagePlaceholder = (
  props: InShiftTaskImagePlaceholderProps,
) => {
  const { icon, text } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup
  let Icon = null;
  if (icon === 'image') Icon = Image;
  if (icon === 'camera') Icon = Camera;
  if (icon === 'camera-slash') Icon = CameraSlash;
  if (icon === 'circle-x') Icon = XCircle;

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="bg-gray-9 flex h-full min-h-[4rem] w-full flex-col items-center justify-center gap-2 rounded p-4">
      {Icon && <Icon color={colors.asphalt.light} size={64} />}

      {text && (
        <span className="text-asphalt-light text-lg font-medium">{text}</span>
      )}
    </div>
  );
};
