import type { InShiftTaskResultImage } from '@shiftsmartinc/shiftsmart-types';
import classnames from 'classnames';
import { type MouseEvent } from 'react';
import {
  InShiftTaskImagePlaceholder,
  InShiftTaskImagePlaceholderProps,
} from './InShiftTaskImagePlaceholder';

export interface InShiftTaskImageProps {
  className?: string;
  image?: InShiftTaskResultImage;
  onClick?: (event: MouseEvent) => void;
  placeholderIcon?: InShiftTaskImagePlaceholderProps['icon'];
  placeholderText?: string;
  title?: string;
}

/**
 * @name InShiftTaskImage
 * @description An InShiftTask pre or post image
 */
export const InShiftTaskImage = (props: InShiftTaskImageProps) => {
  const {
    className,
    image,
    onClick,
    placeholderIcon: placeholderIconProp,
    placeholderText: placeholderTextProp,
    title,
  } = props;

  // Hooks

  // Setup

  const { imageURL } = image ?? {};

  const placeholderIcon = imageURL ? placeholderIconProp : 'camera-slash';
  const placeholderText = imageURL ? placeholderTextProp : 'Photo not Uploaded';

  const showImage = !placeholderIcon && !placeholderText;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'border-gray-8 flex h-fit flex-col gap-4 rounded border',
        className,
      )}
    >
      {title && (
        <div className="flex items-center gap-4 border-b border-gray-200 p-4">
          <div className="flex-1">{title}</div>
        </div>
      )}

      {showImage ? (
        <div className="flex flex-col">
          <img
            alt={title}
            className="max-h-[512px] w-full p-4 hover:cursor-pointer"
            onClick={onClick}
            src={imageURL}
          />
        </div>
      ) : (
        <InShiftTaskImagePlaceholder
          icon={placeholderIcon}
          text={placeholderText}
        />
      )}
    </div>
  );
};
