import { useLocation } from '@remix-run/react';
import { useEffect } from 'react';

export const useRouteChange = (callback: (route: string) => void) => {
  // Hooks
  const { pathname } = useLocation();

  // Setup

  // Handlers

  // Markup

  // Life Cycle
  useEffect(() => {
    callback(pathname);

    console.log(`👋 ~ a route change - pathname:`, { pathname });
  }, [pathname]);
};
