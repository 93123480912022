import { useState, useEffect } from 'react';
import {
  TypedFetcherWithComponents,
  UseDataFunctionReturn,
  useTypedFetcher,
} from 'remix-typedjson';

export type FetcherWithComponentsReset<T> = Omit<
  TypedFetcherWithComponents<T>,
  'data'
> & {
  data: UseDataFunctionReturn<T> | undefined;
  reset: () => void;
};

/**
 * @name useTypedFetcherWithReset
 * @description A hook that returns a fetcher with a reset function
 * @link https://github.com/remix-run/remix/discussions/2749#discussioncomment-7276763
 */
export function useTypedFetcherWithReset<T>(): FetcherWithComponentsReset<T> {
  // Hooks
  const fetcher = useTypedFetcher<T>();
  const [data, setData] = useState<UseDataFunctionReturn<T> | undefined>(
    fetcher.data,
  );

  // Setup

  // Handlers
  const reset = () => setData(undefined);

  // Life Cycle
  useEffect(() => {
    if (fetcher.state === 'idle') {
      setData(fetcher.data);
    }
  }, [fetcher.state, fetcher.data]);

  // 🔌 Short Circuits

  return { ...fetcher, data, reset };
}
