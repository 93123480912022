import { Input, InputProps } from '@shiftsmartinc/shiftsmart-ui';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useSearchParams } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { InferType, Schema } from 'yup';
import { debounce } from 'lodash';

export interface QueryParamTextProps<S extends Schema>
  extends Omit<InputProps, 'id'> {
  id: keyof InferType<S>;

  /**
   * @deprecated We non longer need to supply a schema, we only care about
   * the single query param this component controls.
   */
  schema: S;
}

/**
 * @name QueryParamText
 * @description An Input component that controls a query param
 */
export const QueryParamText = <S extends Schema>(
  props: QueryParamTextProps<S>,
) => {
  const { id, schema: _schema, ...inputProps } = props;

  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  const key = id.toString();
  const defaultText = searchParams.get(key) || '';

  const [text, setText] = useState(defaultText);

  // Setup

  // Handlers
  const fetchData = (text?: string) => {
    /**
     * IMPORTANT: We need to create a new instance of URLSearchParams using
     * the current search params otherwise we will end up with a stale
     * reference to the search params.
     */
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete('page');

    if (text) newSearchParams.set(key, text);
    if (!text) newSearchParams.delete(key);

    setSearchParams(newSearchParams);
  };

  const debounced = debounce(fetchData, 200, {
    leading: false,
    trailing: true,
  });

  const debouncedCallback = useCallback(debounced, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  // Markup
  const defaultLeftIcon = (
    <MagnifyingGlass className="text-gray-6 absolute top-1/2 mx-2 -translate-y-1/2 transform" />
  );

  // Life Cycle
  useEffect(() => {
    debouncedCallback(text);
  }, [text]);

  // 🔌 Short Circuits

  return (
    <Input
      id={String(id)}
      leftIcon={defaultLeftIcon}
      onChange={onChange}
      type="search"
      value={text}
      {...inputProps}
    />
  );
};
