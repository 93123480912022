import {
  DatePickerRange,
  FocusedInputShape,
  type DatePickerRangeProps,
  type OnChangeDatesProp,
  type RenderMonthProps,
} from '@shiftsmartinc/shiftsmart-ui';
import moment, { type Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { type InferType, Schema } from 'yup';
import { useQueryParams } from '../../global/hooks/useQueryParams';
import { formatDate } from '../utils/datetime';

type OptionalFields = 'startDate' | 'endDate' | 'onDatesChange';
export type QueryParamsDateRangeProps<S extends Schema> = Omit<
  DatePickerRangeProps,
  OptionalFields
> &
  Partial<Pick<DatePickerRangeProps, OptionalFields>> &
  RenderMonthProps & {
    endDateId: keyof InferType<S>;
    maxDays?: number;
    onMaxDaysExceeded?: (
      args: OnChangeDatesProp & {
        oldStartDate: OnChangeDatesProp['startDate'];
      },
    ) => void;
    schema: S;
    startDateId: keyof InferType<S>;
    timeZone: string;
  };

/**
 * @name QueryParamsDateRange
 * @description A DatePickerRange component that a pair of queryParams
 */
export const QueryParamsDateRange = <S extends Schema>(
  props: QueryParamsDateRangeProps<S>,
) => {
  const {
    startDateId,
    endDateId,
    schema,
    startDate: startDateProp,
    endDate: endDateProp,
    timeZone,
    onMaxDaysExceeded,
    maxDays,
    ...datePickerRangeProps
  } = props;

  // Hooks
  const { params, setParams } = useQueryParams(schema);

  // Setup
  const startDateParam = params?.[startDateId];
  const endDateParam = params?.[endDateId];

  // Hooks
  const dateToMoment = useCallback(
    (date?: Date) =>
      date ? moment(formatDate(new Date(date), timeZone)) : null,
    [timeZone],
  );
  const momentToDate = useCallback(
    (moment: Moment | null) =>
      moment ? new Date(formatDate(moment.toDate(), timeZone)) : undefined,
    [timeZone],
  );

  const [startDate, setStartDate] = useState<Moment | null>(
    dateToMoment(startDateParam) ?? startDateProp ?? null,
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    dateToMoment(endDateParam) ?? endDateProp ?? null,
  );
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>();

  // Handlers

  const onDatesChange = (args: OnChangeDatesProp) => {
    let { startDate: newStartDate, endDate: newEndDate } = args;

    const isStartDateChanged = newStartDate && !newStartDate?.isSame(startDate);
    const isEndDateChanged = newEndDate && !newEndDate?.isSame(endDate);

    if (isStartDateChanged) newEndDate = null;

    const daysCount = moment(newEndDate).diff(newStartDate, 'days');
    const isMaxDaysExceeded = maxDays && Math.abs(daysCount) >= maxDays;

    if (isEndDateChanged && isMaxDaysExceeded) {
      const oldStartDate = newStartDate;
      newStartDate = moment(newEndDate).subtract(maxDays - 1, 'days');
      onMaxDaysExceeded?.({
        endDate: newEndDate,
        oldStartDate,
        startDate: newStartDate,
      });
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    if (isEndDateChanged) {
      setParams({
        [endDateId]: momentToDate(newEndDate),
        page: undefined,
        [startDateId]: momentToDate(newStartDate),
      });
    }
  };

  const onFocusChange = (newFocusedInput: FocusedInputShape | null) => {
    if (!focusedInput) setFocusedInput('startDate');
    else setFocusedInput(newFocusedInput);
  };

  // Markup

  // Life Cycle
  useEffect(() => {
    setStartDate(dateToMoment(startDateParam) ?? startDateProp ?? null);
    setEndDate(dateToMoment(endDateParam) ?? endDateProp ?? null);
  }, [startDateParam, endDateParam, startDateProp, endDateProp]);

  // 🔌 Short Circuits

  return (
    <DatePickerRange
      endDate={endDate}
      endDateId={String(endDateId)}
      focusedInput={focusedInput}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      startDate={startDate}
      startDateId={String(startDateId)}
      {...datePickerRangeProps}
    />
  );
};
