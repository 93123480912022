import {
  SelectSingle,
  type SelectSingleOption,
  type SelectSingleProps,
} from '@shiftsmartinc/shiftsmart-ui';
import { InferType, Schema } from 'yup';
import { useQueryParams } from '../../global/hooks/useQueryParams';

export interface QueryParamSelectSingleProps<S extends Schema>
  extends Omit<SelectSingleProps, 'id'> {
  id: keyof InferType<S>;
  schema: S;
}

/**
 * @name QueryParamSelectSingle
 * @description A SelectSingle component that controls a QueryParam
 */
export const QueryParamSelectSingle = <S extends Schema>(
  props: QueryParamSelectSingleProps<S>,
) => {
  const { className, id, options, schema, ...selectSingleProps } = props;

  // Hooks
  const { params, setParams } = useQueryParams(schema);

  // Setup
  const value = params[id];

  const selectedOption = options.find((option) => option.value === value);

  // Handlers
  const onChange = (selectedOption: SelectSingleOption) => {
    setParams({
      [id]: selectedOption.value || undefined,
      page: undefined,
    });
  };

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <SelectSingle
      className={className}
      defaultValue={selectedOption}
      id={String(id)}
      onChange={onChange}
      options={options}
      {...selectSingleProps}
    />
  );
};
